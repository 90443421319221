<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  // props: {},
  components: {
    MasterDetail
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      businessUnits: [],
      niveisHierarquicos: [],
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "nivel_hierarquico",
          name: "Nível hierarquico",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.SELECT,
          rel: { to: this.niveisHierarquicos, key: 'id', name: 'nome' },
          colSize: 6,
        },
        {
          key: "business_unit",
          name: "Business Unit",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.SELECT,
          rel: { to: this.businessUnits, key: 'id', name: 'nome' },
          colSize: 6,
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        },
        {
          key: "email",
          name: "E-mail",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        }
      ];
    },
    resourceUrl() {
      return `v1/ativacaoped/${this.clientId}/responsaveis`;
    }
  },
  // filters: {},
  created() {
    this.getBusinessUnits();
    this.getNiveisHierarquicos();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    async getBusinessUnits() {
      try {
        const { get } = this.apiResource(`v1/ativacaoped/${this.clientId}/business-units/selecao`);
        this.businessUnits = await get();
      } catch (error) {
        this.notify(error);
        this.businessUnits = [];
      }
    },
    async getNiveisHierarquicos() {
      try {
        const { get } = this.apiResource(`v1/ativacaoped/${this.clientId}/nivel-hierarquicos/selecao`);
        this.niveisHierarquicos = await get();
      } catch (error) {
        this.notify(error);
        this.niveisHierarquicos = [];
      }
    },
  }
  // watch: {},
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    formTitle="Cadastro de Representante"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :resourceUrl="resourceUrl"
    serverPagination
  ></MasterDetail>
</template>
